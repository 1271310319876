import React from 'react';

const LabelizerIcon = ({color, className}) => (
    <svg width="40" height="47" viewBox="0 0 40 47" fill={color} className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M33.8463 23.1538C33.8463 20.8461 32.0002 18.8461 29.6925 18.5384C28.0002 14.8461 24.3079 12.3846 20.0002 12.3846C19.6925 12.3846 19.231 12.3846 18.9233 12.5384C18.0002 11.4615 16.7694 10.8461 15.3848 10.8461C12.7694 10.8461 10.7694 12.8461 10.7694 15.4615C10.7694 16.0769 10.9233 16.5384 11.0771 17.1538C9.84634 18.8461 9.23096 20.8461 9.23096 23.1538C9.23096 25.4615 9.84634 27.4615 11.0771 29.1538C10.9233 29.7692 10.7694 30.2307 10.7694 30.8461C10.7694 33.4615 12.7694 35.4615 15.3848 35.4615C16.7694 35.4615 18.0002 34.8461 18.9233 33.7692C19.231 33.7692 19.6925 33.9231 20.0002 33.9231C24.3079 33.9231 28.0002 31.3077 29.6925 27.7692C32.0002 27.4615 33.8463 25.4615 33.8463 23.1538ZM15.3848 13.9231C16.3079 13.9231 16.9233 14.5384 16.9233 15.4615C16.9233 16.3846 16.3079 17 15.3848 17C14.4617 17 13.8463 16.3846 13.8463 15.4615C13.8463 14.5384 14.4617 13.9231 15.3848 13.9231ZM15.3848 32.3846C14.4617 32.3846 13.8463 31.7692 13.8463 30.8461C13.8463 29.9231 14.4617 29.3077 15.3848 29.3077C16.3079 29.3077 16.9233 29.9231 16.9233 30.8461C16.9233 31.7692 16.3079 32.3846 15.3848 32.3846ZM20.0002 30.8461C20.0002 28.2307 18.0002 26.2307 15.3848 26.2307C14.6156 26.2307 13.8463 26.3846 13.231 26.8461C12.6156 25.7692 12.3079 24.5384 12.3079 23.1538C12.3079 21.7692 12.6156 20.5384 13.231 19.4615C13.8463 19.9231 14.6156 20.0769 15.3848 20.0769C18.0002 20.0769 20.0002 18.0769 20.0002 15.4615C22.9233 15.4615 25.231 17 26.6156 19.3077C25.3848 20.2307 24.6156 21.6154 24.6156 23.1538C24.6156 24.6923 25.3848 26.0769 26.6156 27C25.231 29.3077 22.9233 30.8461 20.0002 30.8461ZM29.231 24.6923C28.3079 24.6923 27.6925 24.0769 27.6925 23.1538C27.6925 22.2307 28.3079 21.6154 29.231 21.6154C30.154 21.6154 30.7694 22.2307 30.7694 23.1538C30.7694 24.0769 30.154 24.6923 29.231 24.6923Z"
            />
        <path
            d="M20 46.2308C19.6923 46.2308 19.5385 46.2308 19.2308 46.0769L0.769231 35.3077C0.307692 35 0 34.5385 0 33.9231V12.3846C0 11.7692 0.307692 11.3077 0.769231 11L19.2308 0.230769C19.6923 -0.0769231 20.3077 -0.0769231 20.7692 0.230769L39.2308 11L37.6923 13.6154L20 3.46154L3.07692 13.3077V33.1538L20 43L36.9231 33.1538V21.6154H40V33.9231C40 34.5385 39.6923 35 39.2308 35.3077L20.7692 46.0769C20.4615 46.2308 20.3077 46.2308 20 46.2308Z"
            />
    </svg>

);

export default LabelizerIcon;