import HomeSvgIcon from "../components/icons/homeIcon";
import React from "react";
import CampaignIcon from "../components/icons/CampagnIcon";
import SmartAdviceIcon from "../components/icons/smartAdviceIcon";
import AutomationIcon from "../components/icons/AutomationIcon";
import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import AudiencesIcon from "../components/icons/AudiencesIcon";
import ContentIcon from "../components/icons/ContentIcon";
import ConfigurationIcon from "../components/icons/ConfigurationIcon";
import LabelizerIcon from "../components/icons/LabelizerIcon";

const campaignNavItems = [
    // {
    //     id: 'home',
    //     title: 'Home',
    //     icon: <HomeSvgIcon className="home-svg" />,
    //     link: '/'
    // },
    {
        id: 'campaign',
        title: 'Campaign',
        icon: <CampaignIcon className="sidebar-svg"/>,
        link: '/campaign',
        subItems: [
            {
                id: 'campaign_google_ads',
                title: 'Ads',
                link: '/ads'
            },
            // {
            //     id: 'campaign_meta_ads',
            //     title: 'Meta Ads',
            //     link: '/campaign/meta-ads'
            // },
            {
                id: 'campaign_email_marketing',
                title: 'E-mail & Push',
                link: '/email-marketing'
            },
            {
                id: 'external_campaign',
                title: 'Custom',
                link: '/custom-campaigns'
            },

            {
                id: 'external_campaign_channel',
                title: 'Custom Types',
                link: '/empty-types',
                hide: !(JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).user?.role && JSON.parse(localStorage.getItem('user')).user?.role?.length > 0 && JSON.parse(localStorage.getItem('user')).user?.role?.includes('admin'))
            },
            // {
            //     id: 'campaign_push_notification',
            //     title: 'Push-Notification',
            //     link: '/campaign/push-notification'
            // },
        ]
    },
    {
        id: 'smart_advices',
        title: 'Smart Advices',
        icon: <SmartAdviceIcon className="sidebar-svg home-svg"/>,
        link: '/smart-advices',
        subItems: [
            {
                id: 'recommendations',
                title: 'Smart Advices',
                link: '/recommendations'
            },
            {
                id: 'recommendations-category',
                title: 'Categories',
                link: '/recommendations-category',
                hide: !(JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).user?.role && JSON.parse(localStorage.getItem('user')).user?.role?.length > 0 && JSON.parse(localStorage.getItem('user')).user?.role?.includes('admin'))
            },
        ]
    },
    {
        id: 'automation',
        title: 'Automation',
        icon: <AutomationIcon className="sidebar-svg home-svg"/>,
        link: '/automation',
    },
    {
        id: 'labelizer',
        title: 'Labelizer',
        icon: <LabelizerIcon className="sidebar-svg home-svg"/>,
        link: '/labelizer',
    },
    {
        id: 'analytics',
        title: 'Analytics',
        icon: <AnalyticsIcon className="sidebar-svg home-svg"/>,
        subItems: [
            {
                id: 'product_analytics',
                title: 'Products',
                link: '/analytics/products'
            },
            {
                id: 'customers_analytics',
                title: 'Customers',
                link: '/analytics/customers'
            },
        ]
    },
    {
        id: 'audiences',
        title: 'Audiences',
        icon: <AudiencesIcon className="sidebar-svg"/>,
        subItems: [
            {
                id: 'customers',
                title: 'Customers',
                link: '/customers'
            },
            {
                id: 'groups',
                title: 'Groups',
                link: '/customer-groups'
            },
        ]
    },
    {
        id: 'content',
        title: 'Content',
        icon: <ContentIcon className="sidebar-svg"/>,
        subItems: [
            {
                id: 'products',
                title: 'Products',
                link: '/products'
            },
            {
                id: 'orders',
                title: 'Orders',
                link: '/orders'
            },
            {
                id: 'media',
                title: 'Media',
                link: '/content/media'
            },
            {
                id: 'templates',
                title: 'Templates',
                link: '/templates'
            },
        ]
    },
    {
        id: 'configuration',
        title: 'Configuration',
        link: '/configuration/general',
        icon: <ConfigurationIcon className="sidebar-svg config-svg"/>,
        // subItems: [
        //     {
        //         id: 'general',
        //         title: 'General',
        //         link: '/configuration/general'
        //     },
        //     {
        //         id: 'configuration_google_ads',
        //         title: 'Google Ads',
        //         link: '/configuration/google_ads'
        //     },
        //     {
        //         id: 'configuration_meta_ads',
        //         title: 'Meta Ads',
        //         link: '/configuration/meta_ads'
        //     },
        //     {
        //         id: 'configuration_email_marketing',
        //         title: 'E-mail Marketing',
        //         link: '/configuration/email_marketing'
        //     },
        //     {
        //         id: 'configuration_push_notification',
        //         title: 'Push-Notification',
        //         link: '/configuration/push_notification'
        //     },
        // ]
    },
]

export default campaignNavItems
